@media only screen and (min-width: 1440px) {
  .app-menu {
    transition: width 0.5s ease;
    width: 50px;
    overflow-x: hidden;
  }

  .app-menu:hover {
    width: 200px;
  }

  .menu-title {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .app-menu:hover .menu-title {
    opacity: 1;
  }
}
