.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
a {
  text-decoration: none;
  color: #7c878e;
}

a:hover {
  text-decoration: none;
  color: var(--main-color);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.wrapper {
  padding: 20px;
}

.sophyapp-button {
  background-color: var(--main-color);
}

.main_background {
  /* Layout Properties */
  top: 0px;
  left: 0px;
  height: 100vh;
  /* UI Properties */
  background: var(--unnamed-color-f5f5f5) 0% 0% no-repeat padding-box;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
}

.app-header {
  min-height: 7vh;
  background: var(--———-others-———) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 1vh;
  padding-left: 1vh;
  border-bottom: #dcdcdc solid;
  border-width: medium;
}

.app-menu {
  background: var(--———-others-———) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-right: #dcdcdc solid;
  border-width: thin;
}

.menu-row {
  min-height: 7vh;
  text-align: center;
  display: flex;
  justify-content: center;
}

.menu-title {
  text-align: left;
}

.menu-row:hover {
  background-color: var(--background-active);
}

.menu-row:active {
  background-color: var(--background-active);
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.menu-title {
  margin-left: 8px; /* Add some space between the icon and the title */
}

.icon-centered {
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Make the scrollbar track transparent */
}

::-webkit-scrollbar-thumb {
  background-color: lightgrey; /* Set the color of the scrollbar thumb */
  border-radius: 5px; /* Round the corners of the scrollbar thumb */
}

::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Make the scrollbar track transparent */
}

::-webkit-scrollbar-thumb {
  background-color: lightgrey; /* Set the color of the scrollbar thumb */
  border-radius: 5px; /* Round the corners of the scrollbar thumb */
}

.action-header {
  margin-top: 1vh;
}

.action-header-small .back-to-courses .MuiIconButton-root {
  font-size: 0.8rem; /* Smaller font size for the text */
  color: #333; /* Or any color that matches your design */
}

.action-header-small .back-to-courses .MuiSvgIcon-root {
  font-size: 1.2rem; /* Smaller icon size */
}

.action-header-small .back-to-courses span {
  font-size: 0.8rem;
}

.action-header-small {
  padding: 5px 0;
}

.action-header-small .back-to-courses span {
  font-size: 0.8rem;
}

.form-label {
  margin-top: 1rem;
}

.kiro-font {
  font-family: "Kiro", sans-serif !important;
}

.dashboard-header-btn-new-program {
  border: 1px solid var(--unnamed-color-cacfd2);
  border: 1px solid #cacfd2;
  height: 32px;
  background: var(---84bd00-◀︎) 0% 0% no-repeat padding-box;
  background: var(--main-color) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  width: auto;
  letter-spacing: var(--unnamed-character-spacing-0-3);
  color: var(--———-others-———);
  text-align: left;
  font: normal normal normal 15px/26px Kiro;
  letter-spacing: 0.3px;
  color: #ffffff;
  opacity: 1;
  cursor: pointer;
}

.dashboard-header-btn-icon {
  float: right;
  padding-left: 3px;
  padding-top: 8px;
  height: 20px;
  width: 20px;
}

@media (max-width: 1439px) {
  .menu-title {
    display: none;
  }
  .menu-item.no-title {
    justify-content: center;
  }
}

.menu-footer {
  position: fixed;
  bottom: 1vh;
  padding-left: 1vw;
}
.col {
  padding-left: 0;
}

.program-overview {
  margin: 1vh 1vw 9vh 1vw;
  background-color: white;
  border: #dcdcdc solid;
  border-width: thin;
  overflow-y: scroll;
  height: 90vh;
}

.program-container-short {
  background: var(--———-others-———) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-cacfd2);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cacfd2;
  border-radius: 5px;
  opacity: 1;
}

.searchbar {
  background: var(--unnamed-color-f5f5f5) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-e5e7e8);
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #e5e7e8;
  border-radius: 20px;
  opacity: 0; /* temporary to 0 due it only displays the user name at the moment */
  height: 30%;
}

.spacer {
  padding-right: 1vw;
}

.active {
  text-decoration: none;
  color: var(--main-color);
  background-color: var(--background-active);
}

a.active .menu-row {
  background-color: var(--background-active);
}

.searchbar-icon {
  padding-top: 5px;
  float: right;
}

.margin-left-1 {
  margin-left: 5px;
}

.margin-bottom-1 {
  margin-bottom: 10px;
}
.margin10 {
  margin-top: 50px;
}

textarea {
  resize: both;
}

/* Target the select element */
select {
  color: black;
}

/* Add custom arrow for the select element */
select::-webkit-calendar-picker-indicator {
  color: black;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M10 12.5l5-5h-10l5 5z' fill='black'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: right 0.5em center;
  padding-right: 1.5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile Screen Styles */
@media only screen and (min-width: 360px) {
  .header-icons {
    text-align: right;
  }
  .header-icon {
    margin-right: 5px;
  }
  .exit-icon {
    width: 20px;
    height: 20px;
  }
  .container {
    max-width: 380px;
    margin-bottom: 80px;
  }
  .program-overview {
    height: 85vh;
  }
}

/* Tablet Screen Styles */
@media only screen and (min-width: 768px) {
  .header-icon {
    margin: none;
  }
  .profile-icon {
    display: none;
  }
  .container {
    max-width: none;
  }

  .course-details-dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
  }
}

/* Notebook Small Screen Styles */
@media only screen and (min-width: 1024px) {
}

/* Notebook Screen Styles */
@media only screen and (min-width: 1200px) {
  .program-overview {
    height: 90vh;
  }
}

/* Large Screen Styles */
@media only screen and (min-width: 1400px) {
  .exit-icon {
    width: 25px;
    height: 25px;
  }
  .help-icon {
    height: 25px;
    margin-right: -5px;
  }
}
