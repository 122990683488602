.course-details-container {
  background-color: #ffd7aa;
  color: #333;
  padding: 20px;
  min-height: 90vh;
}

.course-details-header h1 {
  font-size: 24px; /* Adjust font-size to match the design */
  margin-bottom: 10px;
}

.course-details-header p {
  font-size: 16px; /* Adjust font-size to match the design */
  margin-bottom: 5px;
}

.course-instructor {
  font-style: italic;
  margin-bottom: 20px;
}

.course-action {
  display: flex;
  justify-content: center; /* Center the button */
  margin: 20px 0;
}

.play-button {
  margin-right: 10px;
}

.completed {
  font-weight: bold;
}

.download-button {
  background-color: #f0a500; /* Adjust color to match the image */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px; /* Adjust font-size to match the design */
  cursor: pointer;
  display: inline-flex; /* Align the icon with the text */
  align-items: center; /* Center the icon with the text vertically */
  justify-content: center; /* Center the text inside the button */
  padding: 10px 20px;
}

.course-sessions h2 {
  font-size: 15px;
  font-weight: bold;
  position: relative;
  margin: 20px 0 0 0;
}

.course-sessions h2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px; /* Adjust the position as needed */
  height: 2px; /* Height of the underline */
  width: 100%; /* Full width */
  background-color: #000; /* Underline color */
}

.session-item {
  display: flex;
  align-items: center;
  padding: 2px 0;
  border-bottom: 1px solid #ccc; /* Adjust color to match the image */
  display: flex; /* Align the items in a row */
  justify-content: space-between; /* Distribute space between items */
  align-items: center; /* Align items vertically */
}

.session-index,
.session-title,
.session-duration {
  margin-right: 10px;
}

.session-index {
  width: 50px;
}

.session-title {
  flex-grow: 1;
}

.session-duration {
  width: 100px;
}

.coupon-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.coupon-input {
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  flex: 1;
}

.coupon-input::placeholder {
  font-size: 12px; /* Adjust the size as needed */
}

.close-button-container {
  margin-top: 10px;
}

@media (max-width: 600px) {
  .coupon-container {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .close-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .course-details-container {
    min-width: 500px;
  }
}
