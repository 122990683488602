.profile-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding-top: 20px;
  height: 100vh;
  background-color: #f0f4f8;
}

.profile-form {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.profile-form h1 {
  color: #333;
  text-align: center;
}

.profile-form label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.profile-form input[type="text"],
.profile-form input[type="email"],
.profile-form input[type="password"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.profile-submit-button {
  width: 100%;
  padding: 10px;
  background-color: #a63dc9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.profile-form button:hover {
  background-color: #15077f;
}

.feedback-link {
  margin-top: 10px;
  text-align: center;
  text-decoration: underline;
  color: #a63dc9;
  cursor: pointer;
}
