/* src/CheckoutForm.css */
.checkout-form {
  box-sizing: border-box;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f6f9fc;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.checkout-form .form-row {
  margin-bottom: 20px;
}

.checkout-form .form-row label {
  display: block;
  margin-bottom: 10px;
  color: #6b7c93;
  font-weight: 300;
}

.checkout-form .form-row input {
  width: 100%;
  padding: 10px 14px;
  font-size: 16px;
  border: 1px solid #cfd7df;
  border-radius: 4px;
  background-color: white;
}

.checkout-form .submit-button {
  display: block;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  background-color: #00a3e1;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.checkout-form .submit-button:hover {
  background-color: #0079ae;
}

.checkout-form .submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
