.courses-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 10px;
  margin-top: 10px;
}

.course-card {
  flex: 0 0 auto;
  margin-right: 10px;
}

.courses-container::-webkit-scrollbar {
  height: 8px;
}

.courses-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.courses-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.courses-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.course-list {
  margin-top: 20px;
}
