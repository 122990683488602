.program-overview-container {
  margin: 2vh;
}

.program-overview-main-mobile {
  margin-bottom: 150px;
}

.app-header-image-class {
  max-height: 52px;
}

.resizer {
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  width: 10px;
  cursor: col-resize;
  z-index: 2;
}

.resize-handle {
  width: 100%;
  height: 20px;
  background-color: #ccc;
  cursor: ns-resize;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-padding-right-row {
  padding-right: 0;
  margin-right: 0;
}

.list-container::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

.list-container::-webkit-scrollbar-track {
  background: transparent; /* Make the scrollbar track transparent */
}

.list-container::-webkit-scrollbar-thumb {
  background-color: lightgrey; /* Set the color of the scrollbar thumb */
  border-radius: 5px; /* Round the corners of the scrollbar thumb */
}

.sb-main-content::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

.sb-main-content::-webkit-scrollbar-track {
  background: transparent; /* Make the scrollbar track transparent */
}

.sb-main-content::-webkit-scrollbar-thumb {
  background-color: lightgrey; /* Set the color of the scrollbar thumb */
  border-radius: 5px; /* Round the corners of the scrollbar thumb */
}

/* Custom scrollbar styles for Firefox */
.list-container {
  scrollbar-width: thin;
  scrollbar-color: lightgrey transparent;
}

/* Remove default scrollbar padding for Internet Explorer and Edge */
.list-container {
  -ms-overflow-style: none;
}
.no-row-padding > .col,
.no-row-padding > [class*="col-"] {
  padding: 0;
}

.container-resizing {
  position: relative;
  overflow: auto;
  min-height: 100px;
  max-height: 500px;
  border: 1px solid #ccc;
}

.resizeHandle {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8px;
  cursor: ns-resize;
  background: linear-gradient(to bottom, transparent, #ccc, transparent);
  opacity: 0.5;
  z-index: 1;
}

.program-overview-row {
  background: var(--———-others-———) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-cacfd2);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cacfd2;
  border-radius: 5px;
  opacity: 1;
  height: 10vh;
  margin-bottom: 2vh;
  margin-right: 0.5vw;
}

.grey-background {
  background-color: #fbf8f8;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.workout-thumbnail {
  margin-top: 1vh;
}

.program-title {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---000000-◀︎);
  text-align: left;
  font: normal normal bold 24px/32px Kiro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 2vh;
}

.program-title-template {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---000000-◀︎);
  text-align: left;
  font: normal normal bold 24px/32px Kiro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 2vh;
  margin-bottom: 20px;
}

.program-progress-title {
  padding-top: 2vh;
}

.program-progress-graph {
  padding-bottom: 2vh;
}

.exercise-details-wrapper {
  margin-top: 2vh;
  margin-bottom: 150px;
}

.range-slider {
  width: 100%;
}

-webkit-slider-thumb:active {
  background-color: var(--main-color);
}
-webkit-slider-thumb,
.custom-range:focus::-webkit-slider-thumb,
.custom-range:focus::-moz-range-thumb,
.custom-range:focus::-ms-thumb {
  box-shadow: var(--main-color);
}

.custom-range::-webkit-slider-thumb {
  background: var(--main-color);
}

.custom-range::-moz-range-thumb {
  background: var(--main-color);
}

.custom-range::-ms-thumb {
  background: var(--main-color);
}

.bs-button-color {
  background: var(--main-color);
  border: 1px var(--main-color) solid;
}

.bs-button-color-original {
  background: #48c9b0;
  border: 1px #48c9b0 solid;
}

.bs-button-color-original:hover {
  background: #17a589;
  border: 1px #17a589 solid;
}

.bs-button-color:hover {
  background: var(--second-color);
  border: 1px var(--main-color) solid;
}

.program-exercise-row {
  margin-bottom: 20px;
}

.preview-row {
  font-size: 10px;
}

.sidebar-filter-elem-5vw {
  width: 5vw;
}

.sidebar-filter-elem-10vw {
  width: 10vw;
}

.filter-buttons button {
  margin-right: 10px;
}

.btn-start-program {
  margin-top: 1vh;
  border: 1px solid var(--unnamed-color-cacfd2);
  border: 1px solid #cacfd2;
  border-radius: 20px;
  opacity: 1;
  opacity: 1;
  letter-spacing: 0.18px;
  text-align: center;
  padding: 5px 0px 2px 5px;
  letter-spacing: var(--unnamed-character-spacing-0-3);
  font: normal normal normal 15px/26px Kiro;
  letter-spacing: 0.3px;
  color: #7c878e;
  width: 8vw;
}

.btn-feedback-program {
  margin-top: 1vh;
  background: var(--main-color) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  border-color: var(--main-color);
  opacity: 1;
  letter-spacing: var(--unnamed-character-spacing-0-18);
  color: var(--———-others-———);
  text-align: left;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  width: 8vw;
  text-align: center;
  padding: 5px 0px 2px 5px;
}

.btn-disabled {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
  color: black !important;
}

.sidebar-filter-title {
  letter-spacing: var(--unnamed-character-spacing-0-32);
  color: var(---7c878e-◀︎);
  text-align: left;
  font: normal normal normal 16px/24px Kiro;
  letter-spacing: 0.32px;
  color: #7c878e;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 10px;
}

.icon-button {
  cursor: pointer;
  color: #000; /* Adjust the color as needed */
}

.flex-icon-container {
  display: flex;
  align-items: center;
}

.sidebar-filter-elem {
  width: 10vw;
  height: 3vh;
  margin-top: 10px;
}

.pagination-exercises {
  margin-bottom: 150px;
}

.list-item-container-modal {
  display: flex;
  align-items: center;
  cursor: move;
  background: #f5f9ea;
  border: 1px lightgrey solid;
  margin-bottom: 5px;
  touch-action: none;
}

.input-edit-prop-note-modal {
  width: 100%;
  border-radius: 5px;
  border: 1px lightgrey solid;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}

/* Mobile Screen Styles */
@media only screen and (min-width: 360px) {
  .program-overview-row-active {
    background: var(--———-others-———) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-cacfd2);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cacfd2;
    border-radius: 5px;
    opacity: 1;
    height: 60vh;
    margin-bottom: 2vh;
    margin-right: 0.5vw;
    border: 1px solid var(--unnamed-color-cee499);
    background: #f5f9ea 0% 0% no-repeat padding-box;
    border: 1px solid #cee499;
  }
  .program-overview-row-inactive {
    border: 1px solid var(--unnamed-color-cacfd2);
    border-radius: 5px;
    opacity: 1;
    height: 60vh;
    margin-bottom: 2vh;
    margin-right: 0.5vw;
    border: 1px solid #cee499;
    background: var(--unnamed-color-fbf1f1) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-efbab8);
    background: #fbf1f1 0% 0% no-repeat padding-box;
    border: 1px solid #efbab8;
    border-radius: 5px;
    opacity: 1;
  }
  .mobile-title {
    margin-top: 2vh;
  }
  .mobile-feedback-details {
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal bold 14px/21px Kiro;
    letter-spacing: 0.28px;
    color: #7c878e;
  }
  .mobile-feedback-title-active {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
      var(--unnamed-line-spacing-24) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-32);

    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 16px/24px Kiro;
    letter-spacing: 0.32px;
    color: var(--main-color);
    opacity: 1;
  }
  .mobile-feedback-title-inactive {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) /
      var(--unnamed-line-spacing-21) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(--unnamed-color-c92627);
    text-align: left;
    font: normal normal normal 14px/21px Kiro;
    letter-spacing: 0.28px;
    color: #c92627;
    opacity: 1;
  }

  .mobile-progress-active {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) /
      var(--unnamed-line-spacing-21) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 14px/21px Kiro;
    letter-spacing: 0.28px;
    color: var(--main-color);
    opacity: 1;
  }
  .mobile-progress-inactive {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) /
      var(--unnamed-line-spacing-21) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(--unnamed-color-c92627);
    text-align: left;
    font: normal normal normal 16px Kiro;
    letter-spacing: 0.28px;
    color: #c92627;
    opacity: 1;
  }
  .program-overview {
    margin: 0;
  }
  .app-header-image {
    margin-bottom: 2px;
  }

  .breadcrumb {
    margin-top: 10px;
  }
  .breadcrumb a {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
      var(--unnamed-line-spacing-27) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-18);
    text-align: left;
    font: normal normal normal 18px/27px Kiro;
    letter-spacing: 0.18px;
    color: var(--main-color);
    opacity: 1;
  }
  .btn-start-program {
    width: 50vw;
  }
  .btn-feedback-program {
    width: 50vw;
  }
  .program-properties {
    border-right: none;
    margin-bottom: 150px;
  }
  .program-finished {
    margin-bottom: 30px;
  }
  .btn-feedback-program-in-properties {
    display: none;
  }
  .program-progress-title-active {
    padding-top: 2vh;
    text-align: left;
    font: normal normal normal 16px Kiro;
    letter-spacing: 0.28px;
    color: var(--main-color);
    opacity: 1;
  }
  .program-progress-title-inactive {
    padding-top: 2vh;
    text-align: left;
    font: normal normal normal 16px Kiro;
    letter-spacing: 0.28px;
    color: #c92627;
    opacity: 1;
  }
  .program-property {
    letter-spacing: var(--unnamed-character-spacing-0-32);
    color: var(---7c878e-◀︎);
    text-align: left;
    letter-spacing: 0.32px;
    color: #7c878e;
    opacity: 1;
  }
  .btn-new-program-mobile {
    border: 1px solid #cacfd2;
    height: 32px;
    background: var(--main-color) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width: 100%;
    color: var(--———-others-———);
    text-align: left;
    font: normal normal normal 15px/26px Kiro;
    letter-spacing: 0.3px;
    color: #ffffff;
    opacity: 1;
    cursor: pointer;
    margin-top: 10px;
    padding-left: 10px;
  }
  .program-thumbnail {
    width: 100%;
    border-radius: 2px;
  }

  .sidebar-filter-elem {
    width: 100%;
    height: 30px;
    margin-top: 10px;
  }
  .program-overview-container {
    margin: 2vh;
    margin-bottom: 150px;
  }

  .btn-program-property-action {
    margin-top: 1vh;
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal normal 15px/26px Kiro;
    letter-spacing: 0.3px;
    color: #7c878e;
    width: 100%;
    text-align: center;
    padding: 5px 0px 2px 5px;
    border: 1px solid var(--unnamed-color-cacfd2);
    border: 1px solid #cacfd2;
    border-radius: 20px;
    opacity: 1;
    background-color: #f8f9fa;
  }

  .btn-program-property-action-delete {
    margin-top: 1vh;
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal normal 15px/26px Kiro;
    letter-spacing: 0.3px;
    color: #c92627;
    width: 100%;
    text-align: center;
    padding: 5px 0px 2px 5px;
    background: var(--unnamed-color-fbf1f1) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-f7dddc);
    background: #fbf1f1 0% 0% no-repeat padding-box;
    border: 1px solid #f7dddc;
    border-radius: 20px;
    opacity: 1;
  }

  .program-sidebar-input-mobile {
    margin-top: 10px;
    background: var(--unnamed-color-f5f5f5) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-e5e7e8);
    width: 100%;
    border: 1px solid #e5e7e8;
    border-radius: 20px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    opacity: 1;
    font-size: 14px;
  }

  .list-container {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
    max-height: 500px;
    width: 100%;
  }
  .list-item-container {
    display: flex;
    align-items: center;
    cursor: move;
    background: #f5f9ea;
    /* max-width: 300px; */
    border: 1px lightgrey solid;
    margin-bottom: 5px;
    touch-action: none;
  }
  .list-item {
    height: 80px;
    width: 300px;
  }
  .list-item i {
    margin-right: 20px;
    font-size: 15px;
  }
  .list-item-info {
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal normal 15px/21px Kiro;
    letter-spacing: 0.3px;
    color: #7c878e;
    opacity: 1;
  }
  .list-item-image {
    max-width: 100%;
  }
  .list-item-actions {
  }
  .list-item-edit {
    margin-bottom: 10px;
  }
  .list-item-action-icon {
    margin-right: 10px;
  }
  .program-notes {
    margin-left: 10px;
    margin-top: 10px;
  }
}

/* Tablet Screen Styles */
@media only screen and (min-width: 768px) {
  .program-sidebar-input-mobile {
    margin-top: 10px;
    background: var(--unnamed-color-f5f5f5) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-e5e7e8);
    width: 50%;
    border: 1px solid #e5e7e8;
    border-radius: 20px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    opacity: 1;
    font-size: 14px;
  }
  .btn-start-program {
    width: 20vw;
  }
  .btn-feedback-program {
    width: 20vw;
  }
  .program-overview-row-active {
    background: var(--———-others-———) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-cacfd2);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cacfd2;
    border-radius: 5px;
    opacity: 1;
    height: 15vh;
    min-height: 150px;
    margin-bottom: 2vh;
    margin-right: 0.5vw;
    background: #f5f9ea 0% 0% no-repeat padding-box;
    overflow: hidden;
  }
  .program-overview-row-inactive {
    border: 1px solid #cacfd2;
    border-radius: 5px;
    opacity: 1;
    height: 15vh;
    margin-bottom: 2vh;
    margin-right: 0.5vw;
    background: #fbf1f1 0% 0% no-repeat padding-box;
    border: 1px solid #efbab8;
    overflow: hidden;
  }
  .mobile-title {
    display: none;
  }
  .mobile-details {
    display: none;
  }
  .mobile-feedback-details {
    display: none;
  }
  .mobile-feedback-title-active,
  .mobile-feedback-title-inactive {
    display: none;
  }
  .mobile-progress-active,
  .mobile-progress-inactive {
    display: none;
  }
  .mobile-progress-bar {
    display: none;
  }
  .mobile-progress-title {
    display: none;
  }

  .program-overview {
    margin: 1vh 1vw 9vh 1vw;
  }
  .app-header-image {
    margin-bottom: 0;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .program-properties {
    border-right: #dcdcdc solid;
    border-width: 1px;
    margin-bottom: 250px;
  }
  .program-finished {
    display: none;
  }
  .btn-feedback-program-in-properties {
    display: block;
  }
  .is-invalid {
    border: red 2px solid !important;
  }

  .btn-new-program {
    border: 1px solid #cacfd2;
    height: 32px;
    background: var(--main-color) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width: 10vw;
    color: var(--———-others-———);
    text-align: left;
    font: normal normal normal 15px/26px Kiro;
    letter-spacing: 0.3px;
    color: #ffffff;
    opacity: 1;
    cursor: pointer;
    margin-top: 10px;
    padding-left: 10px;
  }

  .program-progress-active {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) /
      var(--unnamed-line-spacing-21) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 16px Kiro;
    letter-spacing: 0.28px;
    color: var(--main-color);
    opacity: 1;
  }
  .program-progress-inactive {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) /
      var(--unnamed-line-spacing-21) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(--unnamed-color-c92627);
    text-align: left;
    font: normal normal normal 16px Kiro;
    letter-spacing: 0.28px;
    color: #c92627;
    opacity: 1;
  }
  .program-progress-bar-active {
    background: var(---84bd00-◀︎) 0% 0% no-repeat padding-box;
    background: var(--main-color) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
  }
  .program-progress-bar-inactive {
    background: var(--unnamed-color-c92627) 0% 0% no-repeat padding-box;
    background: #c92627 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
  }
  .feedback-title-active {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
      var(--unnamed-line-spacing-24) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-32);

    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 16px/24px Kiro;
    letter-spacing: 0.32px;
    color: var(--main-color);
    opacity: 1;
  }
  .feedback-title-inactive {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
      var(--unnamed-line-spacing-24) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-32);
    color: var(--unnamed-color-c92627);
    text-align: left;
    font: normal normal normal 16px/24px Kiro;
    letter-spacing: 0.32px;
    color: #c92627;
    opacity: 1;
  }
  .feedback-details {
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal bold 14px/21px Kiro;
    letter-spacing: 0.28px;
    color: #7c878e;
  }

  .page-header-title {
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 20px/30px Kiro;
    letter-spacing: 0px;
    color: var(--main-color);
    opacity: 1;
    margin-top: 10px;
    margin-left: 10px;
  }

  .page-header-breadcrumb {
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 14px/26px Kiro;
    letter-spacing: 0.28px;
    color: var(---7c878e-◀︎);
    opacity: 1;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
  }

  .page-header-breadcrumb-active {
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 14px/26px Kiro;
    letter-spacing: 0.28px;
    color: var(--main-color);
    opacity: 1;
  }

  .program-sidebar-header {
    letter-spacing: var(--unnamed-character-spacing-0-32);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal normal 16px/24px Kiro;
    letter-spacing: 0.32px;
    color: #7c878e;
    opacity: 1;
  }

  .program-notes {
    margin-top: 10px;
  }

  .list-container {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
    max-height: 500px;
    resize: vertical;
    -webkit-resize: vertical;
  }
  .list-item-container {
    display: flex;
    align-items: center;
    cursor: move;
    background: #f5f9ea;
    /* max-width: 80%; */
    border: 1px lightgrey solid;
    margin-bottom: 5px;
    touch-action: none;
  }
  .list-item {
    height: 80px;
    width: 300px;
  }
  .list-item i {
    margin-right: 20px;
    font-size: 15px;
  }
  .list-item-info {
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal normal 15px/21px Kiro;
    letter-spacing: 0.3px;
    color: #7c878e;
    opacity: 1;
  }
  .list-item-image {
    max-width: 100%;
  }
  .list-item-actions {
  }
  .list-item-edit {
    margin-bottom: 10px;
  }
  .list-item-action-icon {
    margin-right: 10px;
  }
  .program-preview-title {
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal normal 15px/26px Kiro;
    letter-spacing: 0.3px;
    color: #7c878e;
    opacity: 1;
    width: 100%;
  }

  .program-preview-title-header {
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal normal 15px/26px Kiro;
    letter-spacing: 0.3px;
    color: #7c878e;
    opacity: 1;
  }
  .program-preview-name {
    letter-spacing: var(--unnamed-character-spacing-0-18);
    color: var(---000000-◀︎);
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
  }

  .sidebar-filter-container {
    margin-bottom: 10px;
  }

  .sidebar-filter-elem {
    width: 50%;
    height: 3vh;
    margin-top: 10px;
  }
  .sidebar-filter-elem-template {
    width: 100% !important;
    height: 3vh;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 900px) {
  .sidebar-filter-elem {
    width: 10vw;
    height: 3vh;
    margin-top: 10px;
  }
  .program-overview-container {
    margin: 2vh;
    overflow-y: scroll;
    height: 70vh;
  }
  .program-sidebar-input-mobile {
    margin-top: 10px;
    background: var(--unnamed-color-f5f5f5) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-e5e7e8);
    width: 10vw;
    border: 1px solid #e5e7e8;
    border-radius: 20px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    opacity: 1;
    font-size: 14px;
  }
  .program-notes {
    margin-left: 0;
  }

  .list-container {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
    height: 200px;
    max-height: 500px;
    resize: vertical;
    -webkit-resize: vertical;
  }
  .list-item-container {
    display: flex;
    align-items: center;
    cursor: move;
    background: #f5f9ea;
    /* max-width: 105px; */
    border: 1px lightgrey solid;
    margin-bottom: 5px;
    touch-action: none;
    /* width: 107%; due to scrollbar - bad practice - fix later! */
  }
}

/* Notebook Small Screen Styles */
@media only screen and (min-width: 1024px) {
  .btn-start-program {
    width: 15vw;
  }
  .btn-feedback-program {
    width: 15vw;
  }
  .input-edit-prop {
    display: inline-block;
    width: 30px;
    border-radius: 5px;
    border: 1px lightgrey solid;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
  }
  .btn-program-property-action {
    margin-top: 1vh;
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal normal 15px/26px Kiro;
    letter-spacing: 0.3px;
    color: #7c878e;
    width: 8vw;
    text-align: center;
    padding: 5px 0px 2px 5px;
    border: 1px solid var(--unnamed-color-cacfd2);
    border: 1px solid #cacfd2;
    border-radius: 20px;
    opacity: 1;
  }

  .btn-program-property-action-delete {
    margin-top: 1vh;
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal normal 15px/26px Kiro;
    letter-spacing: 0.3px;
    color: #c92627;
    width: 8vw;
    text-align: center;
    padding: 5px 0px 2px 5px;
    background: var(--unnamed-color-fbf1f1) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-f7dddc);
    background: #fbf1f1 0% 0% no-repeat padding-box;
    border: 1px solid #f7dddc;
    border-radius: 20px;
    opacity: 1;
  }

  .list-container {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
    height: 200px;
    max-height: 500px;
  }
  .list-item-container {
    display: flex;
    align-items: center;
    cursor: move;
    background: #f5f9ea;
    /* max-width: 160px; */
    border: 1px lightgrey solid;
    margin-bottom: 5px;
    touch-action: none;
  }
}

/* Notebook Screen Styles */
@media only screen and (min-width: 1200px) {
  .btn-start-program {
    width: 8vw;
  }
  .btn-feedback-program {
    width: 8vw;
  }
  .list-container {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
    height: 300px;
    max-height: 500px;
  }
  .list-item-container {
    display: flex;
    align-items: center;
    cursor: move;
    background: #f5f9ea;
    /* max-width: 170px; */
    border: 1px lightgrey solid;
    margin-bottom: 5px;
    touch-action: none;
  }
}

/* Large Screen Styles */
@media only screen and (min-width: 1400px) {
  .program-overview-row {
    height: 18vh;
  }
  .list-container {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
    height: 350px;
    max-height: 500px;
  }
  .list-item-container {
    display: flex;
    align-items: center;
    cursor: move;
    background: #f5f9ea;
    /* max-width: 220px; */
    border: 1px lightgrey solid;
    margin-bottom: 5px;
    touch-action: none;
  }
}

/* Extra Large Screen Styles */
@media only screen and (min-width: 1770px) {
  .program-overview-row {
    height: 20vh;
  }
  .list-container {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
    height: 300px;
    max-height: 500px;
  }
  .list-item-container {
    display: flex;
    align-items: center;
    cursor: move;
    background: #f5f9ea;
    /* max-width: 300px; */
    border: 1px lightgrey solid;
    margin-bottom: 5px;
    touch-action: none;
  }
}
