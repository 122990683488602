.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  height: auto; /* Instead of 100vh, to avoid unnecessary scrolling */
  background-color: #f0f4f8;
}

.payment-card {
  margin-bottom: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px; /* Adjust width as needed */
}

.payment-card h5 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.transaction-table-container {
  width: 80%;
  max-width: 500px; /* Adjust width as needed */
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.transaction-table {
  min-width: 300px;
}

.transaction-table th,
.transaction-table td {
  padding: 16px; /* Adjust padding as needed */
}

/* Add styles for the MUI icons if needed */
.payment-icon {
  margin-right: 10px;
  vertical-align: bottom;
}

/* Button styles to match the profile form */
.payment-button {
  width: auto; /* Adjust width as needed */
  padding: 10px 15px;
  background-color: #a63dc9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; /* To separate from the table */
}

.payment-button:hover {
  background-color: #15077f;
}

.payment-card-content {
  background: linear-gradient(135deg, #a63dc9 0%, #15077f 100%);
  color: white;
  padding: 20px;
  border-radius: 8px 8px 0 0; /* Rounded corners at the top only */
  position: relative; /* For positioning the icon or any other element */
  box-shadow: inset 0 -4px 6px rgba(0, 0, 0, 0.2); /* Inner shadow at the bottom */
}

.payment-icon {
  font-size: 3rem; /* Larger icon */
  position: absolute; /* Position the icon */
  top: 20px;
  left: 20px;
}

.payment-title {
  text-align: center;
  margin-bottom: 10px;
}

.payment-amount {
  text-align: center;
  font-size: 2rem; /* Larger font size for amount */
  font-weight: bold; /* Bold for emphasis */
}

/* Adding an after pseudo-element for decorative purpose */
.payment-card-content::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: rgba(255, 255, 255, 0.2); /* Subtle highlight at the bottom */
}
