/* CourseCard.css */
.course-card {
  position: relative;
  display: flex;
  /* align-items: center; */
  align-items: flex-start;
  justify-content: space-between;
  min-width: 250px;
  height: 140px;
  border-radius: 8px;
  padding: 10px;
  margin: 5px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out;
  color: #ffffff;
}

.course-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.course-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}

.course-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  /* color: #333; */
}

.course-title.admin-mode {
  max-width: calc(100% - 120px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course-prescription {
  margin: 4px 0 0;
  font-size: 16px;
  /* color: #484747; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.course-actions {
  display: flex;
  gap: 4px;
  flex-shrink: 0;
  margin-left: 8px;
}

.course-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.instructor-name {
  margin: 4px 0 0;
  font-size: 14px;
}

.like-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
}

.like-button:focus {
  outline: none;
}

.like-button:active {
  transform: scale(0.9);
}

.courses-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 16px;
}

/* .courses-container::-webkit-scrollbar {
  display: none;
} */

.course-actions {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.action-button {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px; /* Adjust size as needed */
}

.action-button:hover {
  opacity: 0.8;
}

.action-button:focus {
  outline: none;
}

.action-button:active {
  transform: scale(0.9);
}

.course-price {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5); /* A semi-transparent background */
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  font-size: 14px; /* Adjust the font size as needed */
  gap: 4px; /* Adds a small space between text and the icon */
}

.course-price .MuiSvgIcon-root {
  font-size: 1.2em; /* Adjust icon size as needed */
}
