.user-management {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-management h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.group-form,
.group-list,
.user-list {
  margin-top: 20px;
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.group-list,
.user-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}
.list-heading {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.user-list-item,
.group-list-item {
  padding: 10px 5px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-list-item:last-child,
.group-list-item:last-child {
  border-bottom: none;
}

.user-list-item:hover,
.group-list-item:hover {
  background-color: #f0f0f0;
}

@media (max-width: 600px) {
  form,
  .list-container {
    width: 100%;
    padding: 10px;
  }

  .user-management {
    padding: 10px;
  }
}
