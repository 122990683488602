/* GroupForm.css or integrate in your existing CSS file */
.group-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.group-name-input {
  width: 100%;
  padding: 8px 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.group-form h3 {
  margin-bottom: 20px;
  color: #333;
}

.group-form input[type="text"]:focus,
.group-form button:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.group-form button {
  margin-top: 20px;
  width: 100%;
  padding: 10px 0;
  background-color: #a63dc9;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.group-form button:hover {
  background-color: #a63dc9;
}

.user-selection-item {
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.user-selection-item:hover {
  background-color: #f5f5f5;
}

.user-selected {
  background-color: #def0d8; /* A light green background to indicate selection */
  border-color: #4caf50; /* Green border to match the button color */
  box-shadow: 0 2px 5px -2px #4caf50;
}

.users-list-container {
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto;
  border: 1px solid #ccc; /* Optional for styling */
  margin-top: 10px;
  padding: 5px;
}
