.category-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.category-item {
  flex: 1 1 calc(33.333% - 20px);
  margin-bottom: 20px;
}

.category-card {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.category-card h5 {
  margin-bottom: 15px;
  font-size: 1.25rem;
  font-weight: 600;
}

.category-card .btn {
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
}

.category-card .btn-secondary {
  background-color: #f44336;
  border-color: #f44336;
  color: #fff;
}

.category-card .btn-secondary:hover {
  background-color: #d32f2f;
  border-color: #d32f2f;
}

.category-card .btn-outlined {
  border: 1px solid #3f51b5;
  color: #3f51b5;
}

.category-card .btn-outlined:hover {
  background-color: #3f51b5;
  color: #fff;
}

.color-pickers-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.color-picker {
  flex: 1;
}

@media (max-width: 768px) {
  .color-pickers-container {
    flex-direction: column;
  }
}
