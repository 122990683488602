@font-face {
  font-family: "kiro";
  src: url(../public/fonts/Kiro-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "kiro-thin";
  src: url(../public/fonts/Kiro-Thin.ttf) format("truetype");
}

body,
html {
  height: 100%;
  margin: 0;
  font-family: kiro;
  background: var(--unnamed-color-f5f5f5) 0% 0% no-repeat padding-box;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::placeholder {
  font-family: kiro;
  font-size: 1.2em;
}

:root {
  --safe-area-bottom: max(
    env(safe-area-inset-bottom),
    constant(safe-area-inset-bottom),
    0px
  );
}

.app-container {
  padding-bottom: calc(var(--safe-area-bottom) + 50px);
}
